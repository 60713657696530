<template>
  <app>
    <template v-slot:content>
      <not-found />
    </template>
  </app>
</template>

<script>
const App = () => import(/* webpackChunkName: "NotFoundPage" */ "@/App.vue");
const NotFound = () => import(/* webpackChunkName: "NotFoundPage" */ "@/views/NotFound.vue");

export default {
  name: "NotFoundApp",
  components: {
    App,
    NotFound
  }
};
</script>
